import styled from 'styled-components';
import { colors } from '../../atoms/colors';
import shadow from '../../atoms/shadows';
import { font } from '../../atoms/typography';

export const BlogCardDeckStyles = styled.section`
  padding: 80px 0;
  height: 100%;

  @media (max-width: 991px) {
    .card-wrapper {
      margin-bottom: 32px;
    }

    .row:has(.card-wrapper) {
      margin-bottom: -32px;
    }
  }

  @media (max-width: 768px) {
    padding: 64px 0;
  }

  .container {
    max-width: 1200px;
  }

  .heading-info {
    max-width: 770px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 56px;
    text-overflow: ellipsis;

    .blog-heading-kicker {
      ${font('overline', 'md', 700)}
      text-align: center;
      color: #1c3eff;
      margin-bottom: 32px;
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 43.95px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.3px;
      color: #1d2030;
      margin-bottom: 32px;
    }

    .subhead {
      font-style: normal;
      font-weight: 400;
      font-size: 22.5px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.27px;
      color: #6f7283;
    }
  }

  a {
    text-decoration: none;
  }
`;

export const BlogCardStyles = styled.div`
  position: relative;
  background-color: ${colors.base.white};
  height: 100%;
  border-radius: 12px;
  ${shadow('xl')}
  overflow: hidden;

  &:hover {
    .card-image {
      .gatsby-image-wrapper {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .overlay-link {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    &:hover {
      text-decoration: none;
    }
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    z-index: 1;
    height: 100%;
    cursor: pointer;
    border: none;

    @media (max-width: 992px) {
      margin-bottom: 24px;
    }

    &-image {
      figure {
        height: 290px;

        .gatsby-image-wrapper {
          height: 100%;

          img {
            display: block;
            object-fit: cover;
            transform-origin: center;
            transition: transform 300ms ease-in-out 0s !important;
          }
        }
      }
    }

    .card-image {
      flex: 0 0 290px;
      height: 290px;
      overflow: hidden;

      img {
        object-fit: cover;
      }

      .featured-blog-bg {
        background-color: var(--primary-color);

        img {
          width: 200px;
          max-width: 100%;
        }
      }
    }

    .card-body {
      padding: 24px;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 44px;
      justify-content: space-between;

      .cat-link {
        pointer-events: all;
        position: relative;
        text-decoration: none;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .tags {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        gap: 8px;
      }

      .meta {
        ${font('text', 'xs', '400')};
        color: ${colors.gray[600]};
        white-space: nowrap;
        padding-left: 16px;
      }
    }

    .label {
      ${font('text', 'sm', '400')}
      border-radius: 2px;
      padding: 2px 6px;
      margin-bottom: 16px;
      width: max-content;
    }

    .card-title {
      ${font('display', 'sm', '700')};
      color: ${colors.gray[900]};
      margin-bottom: 12px;
    }

    .excerpt {
      ${font('text', 'sm', '400')};
      color: ${colors.gray[700]};
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;
