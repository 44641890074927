import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import BlogCard from './BlogCard';
import { BlogCardDeckStyles } from '../../styles/Components/BlogCardDeck/BlogCardDeckStyles';
import { Eyebrow } from '../../styles/Components/CardDeck/ThankYouCardDeckStyles';

const ThankYouCardDeck = ({ industry, product, category, component }) => {
  const { blogs } = useStaticQuery(query);
  const cardDeckHeading = component?.heading;
  const cardDeckSub = component?.subhead;

  return (
    <BlogCardDeckStyles>
      <Container className="justify-content-center">
        <Eyebrow>Related Resources</Eyebrow>
        <Row className="heading-info">
          <h2>{cardDeckHeading}</h2>
          <div
            className="subhead"
            dangerouslySetInnerHTML={{ __html: cardDeckSub }}
          />
        </Row>
        <Row>
          {blogs.nodes.slice(0, 3).map(card => (
            <Col sm={6} lg={4} key={card.id}>
              <BlogCard card={card} />
            </Col>
          ))}
        </Row>
      </Container>
    </BlogCardDeckStyles>
  );
};

const query = graphql`
  {
    blogs: allDatoCmsBlogPost(
      limit: 10
      sort: { fields: publishDate, order: DESC }
      filter: { meta: { status: { eq: "published" } } }
    ) {
      nodes {
        id
        title
        excerpt
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        industry {
          name
          tagColor {
            hex
          }
        }
        category {
          name
          tagColor {
            hex
          }
          tagBackground {
            hex
          }
        }
        tag {
          name
        }
        image {
          gatsbyImageData(
            imgixParams: {
              fm: "webp"
              auto: "compress"
              maxW: 1080
              fit: "clip"
              q: 35
            }
          )
          fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
            ...GatsbyDatoCmsFluid
          }
          alt
          url
        }
        metaTags {
          description
        }
        content {
          value
        }
      }
    }
  }
`;

export default ThankYouCardDeck;
