import styled from 'styled-components';
import { Row } from 'react-bootstrap';

import { font } from '../../atoms/typography';

export const Eyebrow = styled(Row)`
  width: 100%;
  padding: 2rem;
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  color: #1c3eff;
  ${font('overline', 'sm', '700')}
`;
