import React from 'react';
import Card from 'react-bootstrap/Card';
import logo from '../../images/sm-light-logo.svg';
import Badge from '../../molecules/Badge/Badge';
import { colors } from '../../styles/atoms/colors';
import { BlogCardStyles } from '../../styles/Components/BlogCardDeck/BlogCardDeckStyles';
import getReadTimeLabel from '../../utils/getReadTimeLabel';
import Img from '../../utils/OptimizedImage';
import PersistLink from '../PersistLink/PersistLink';

const BlogCard = ({ card }) => {
  const {
    title,
    excerpt,
    image,
    slug,
    category,
    metaTags,
    contentWordCounter,
  } = card;
  const categoryArray = Array.isArray(category) ? category : [category];
  const wordCount = contentWordCounter
    ? JSON.parse(contentWordCounter).words
    : 0;

  return (
    <BlogCardStyles>
      <PersistLink
        to={`/blog/${slug}`}
        className="overlay-link"
        aria-label={title}
      />
      <Card>
        <div className="card-image">
          {image?.gatsbyImageData ? (
            <figure className="img-wrapper blog-image">
              <Img image={image?.gatsbyImageData} alt={image?.alt} />
            </figure>
          ) : (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center featured-blog-bg img-wrapper">
              <img src={logo} alt="Shopmonkey Logo" loading="lazy" />
            </div>
          )}
        </div>
        <Card.Body>
          <div className="content">
            <h5 className="card-title">{title}</h5>
            {excerpt ? (
              <div className="excerpt">{excerpt}</div>
            ) : (
              <div className="excerpt">{metaTags?.description}</div>
            )}
          </div>
          <div className="footer">
            <div className="tags">
              {categoryArray?.length > 0 &&
                categoryArray.map(cat => (
                  <Badge
                    key={cat?.name}
                    badgeColor={colors.primary[50]}
                    size="sm"
                    textColor={colors.primary[500]}
                  >
                    {cat?.name}
                  </Badge>
                ))}
            </div>
            <div className="meta">{getReadTimeLabel(wordCount)}</div>
          </div>
        </Card.Body>
      </Card>
    </BlogCardStyles>
  );
};

export default BlogCard;
